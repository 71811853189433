import { Typography, Grid } from '@mui/material';
import React from 'react';
import { Organisation } from 'requests/nswag/atlas/AtlasClient';
import OrganisationDotMenu from 'Components/OrganisationDotMenu';
import { TreeItem } from '@mui/x-tree-view';
import LocationsDepartment from './LocationDepartment';

type LocationOrganisationProps = {
  organisation: Organisation;
};

const LocationOrganisation: React.FC<LocationOrganisationProps> = (props) => {
  const { organisation } = props;

  return (
    <TreeItem
      key={organisation.id}
      itemId={organisation.id}
      label={
        <Grid container direction="row" justifyContent="space-between">
          <Typography p={1}>{organisation.name}</Typography>
          <OrganisationDotMenu organisation={organisation} />
        </Grid>
      }
    >
      {organisation.departments.map((department) => {
        return <LocationsDepartment department={department} key={department.id} />;
      })}
    </TreeItem>
  );
};

export default LocationOrganisation;
