import { Checkbox, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { FormAssignmentResponseWithNameAndLocationType } from 'requests/hooks/useFetchStandardFormLocations';
import StatusLabel from './helpers/StatusLabel';
import { useStandardFormContext } from './StandardFormProvider';

type StandardFormsTableFormsFormRowProps = {
  form: FormAssignmentResponseWithNameAndLocationType;
  indent?: boolean;
};

function StandardFormsTableFormsFormRow({ form, indent = false }: StandardFormsTableFormsFormRowProps) {
  const { setSelectedForm, selectedFolderIds } = useStandardFormContext();

  const handleRowClick = () => {
    setSelectedForm(form.id);
  };

  return (
    <TableRow key={form.id} onClick={handleRowClick}>
      <TableCell width={60} />
      <TableCell component="th" scope="row" style={{ paddingLeft: indent ? 48 : 16 }}>
        {form.title}
      </TableCell>
      <TableCell width={120}>
        <StatusLabel archived={form.archived} />
      </TableCell>
      <TableCell width={100} align="center">
        <Checkbox
          checked={!!form.isChecked || (!!form.folderId && selectedFolderIds.includes(form.folderId))}
          disabled={!!form.folderId && selectedFolderIds.includes(form.folderId)}
        />
      </TableCell>
    </TableRow>
  );
}
export default StandardFormsTableFormsFormRow;
