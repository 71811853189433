import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactNode } from 'react';
import { Organisation } from 'requests/nswag/atlas/AtlasClient';

type LocationSingleOrganisationsSelectorWithSearchProps = {
  onSelectedLocation: (locationId: string) => void;
  selectedLocation: Organisation | '';
  location: Organisation[];
};

const LocationSingleOrganisationsSelectorWithSearch: React.FC<LocationSingleOrganisationsSelectorWithSearchProps> = (
  props,
) => {
  const { onSelectedLocation, location, selectedLocation } = props;
  const onSelectLocation = (event: SelectChangeEvent<string>, _child: ReactNode) => {
    onSelectedLocation(event.target.value as string);
  };

  const selectedValue = (id: unknown | string) => {
    const selectedId = id as string;
    const value = location?.find((l) => l.id === selectedId)?.name;
    return value;
  };

  if (!location) return null;

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="select-single-organisation">Choose location</InputLabel>
      <Select
        onChange={onSelectLocation}
        value={selectedLocation && selectedLocation.id}
        renderValue={selectedValue}
        label={'Choose location'}
        labelId={'select-single-organisation'}
        fullWidth
      >
        <MenuItem value={''}>None</MenuItem>
        {location.map((org) => {
          return (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default LocationSingleOrganisationsSelectorWithSearch;
