import {
  FormClient,
  FormResponse,
  GetFormsForOwnersBatchInternalRequest,
} from 'requests/nswag/observations/ObservationsClient';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useCallback } from 'react';

function useFetchStandardForms(customerId: string, locationsIds: string[]): UseQueryResult<FormResponse[]> {
  const getStandardForms = useCallback(() => {
    const formClient = new FormClient();
    if (!locationsIds || locationsIds.length === 0) return [];
    const getFormsForOwnersBatchRequest: GetFormsForOwnersBatchInternalRequest =
      new GetFormsForOwnersBatchInternalRequest();
    getFormsForOwnersBatchRequest.customerId = customerId;
    getFormsForOwnersBatchRequest.includeArchived = true;
    getFormsForOwnersBatchRequest.ownerIds = locationsIds;
    return formClient.getFormsForOwnersBatchInternal(getFormsForOwnersBatchRequest);
  }, [customerId, locationsIds]);

  return useQuery({
    queryKey: ['STANDARD_FORMS', customerId],
    queryFn: getStandardForms,
    enabled: locationsIds.length > 0,
  });
}

export default useFetchStandardForms;
