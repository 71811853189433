import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TopBar from './TopBar';
import NavBar from './NavBar';
import NotSekoiaAdminErrorView from './NotSekoiaAdminErrorView';
import { AuthContext } from '../Authentication/AuthProvider';
import { useEffect } from 'react';
import { usePrevious } from 'utils/usePrevious';
import { Box, Stack } from '@mui/material';

export interface ExtendedCustomerResponse {
  name: string;
  id: string;
  compassUrl: string | undefined;
}
export interface ICustomerContext {
  customer: ExtendedCustomerResponse | null;
  setCustomer: React.Dispatch<React.SetStateAction<ExtendedCustomerResponse | null>>;
}

export const CustomerContext = React.createContext<ICustomerContext>({
  customer: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCustomer: () => {},
});

const Homepage: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { isAuthenticated, login } = useContext(AuthContext);
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const [customer, setCustomer] = useState<ExtendedCustomerResponse | null>(null);

  const customerContextValue = useMemo(
    () => ({
      customer,
      setCustomer,
    }),
    [customer, setCustomer],
  );

  useEffect(() => {
    if (!isAuthenticated && !prevIsAuthenticated) login();
  }, [isAuthenticated, login, prevIsAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <CustomerContext.Provider value={customerContextValue}>
      <TopBar />
      <NotSekoiaAdminErrorView>
        <Box position={'absolute'} sx={{ width: '100%', height: 'calc(100vh - 64px)', top: 64 }}>
          <Stack direction={'row'} height={'100%'}>
            <NavBar />
            <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>{props.children}</Box>
          </Stack>
        </Box>
      </NotSekoiaAdminErrorView>
    </CustomerContext.Provider>
  );
};

Homepage.propTypes = {
  children: PropTypes.any,
};

export default Homepage;
