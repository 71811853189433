import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useRenameLocation from 'requests/hooks/useRenameLocation';
import { LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import { Apartment, Department, Organisation } from 'requests/nswag/atlas/AtlasClient';

interface IRenameLocationDialogProps {
  closeDialog: () => void;
  location: Organisation | Department | Apartment;
}

export default function RenameLocationDialog(props: IRenameLocationDialogProps) {
  const { closeDialog, location } = props;
  const [name, setName] = useState(location.name);
  const locationMutation = useRenameLocation();
  const customer = useCustomerContext();
  const { enqueueSnackbar } = useSnackbar();

  const renameLocation = () => {
    try {
      if (name !== undefined) {
        locationMutation.mutate({ id: location.id, name, customerId: customer.id });
        closeDialog();
      }
    } catch (error) {
      closeDialog();
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open>
      <DialogTitle>Rename Location</DialogTitle>
      <DialogContent style={{ height: 80, width: 200 }}>
        {locationMutation.isPending ? (
          <LinearProgress />
        ) : (
          <TextField
            onChange={(event) => setName(event.currentTarget.value)}
            value={name}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            closeDialog();
            event.stopPropagation();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button disabled={location?.name === name || !name} onClick={renameLocation} color="primary">
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}
