import React from 'react';
import { Box, Card, CardContent, CardHeader, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTogglesContext } from './TogglesProvider';
import TogglesContent from './TogglesContent';

const tooltipText = `Toggles work the following way:
If a toggle is enabled on the customer level, it is enabled for all organisations within that customer that either have the toggle ENABLED or NOT CONFIGURED.
In the same way, if a toggle is disabled on the customer level, it is disabled for all organisations within the customer that have the toggle DISABLED or NOT CONFIGURED.
`;

const TogglesView: React.FC = () => {
  const { toggles } = useTogglesContext();

  return (
    <Box p={2}>
      <Card>
        <CardHeader
          title={'Toggles'}
          action={
            <Tooltip title={tooltipText}>
              <InfoIcon fontSize="large" sx={{ float: 'right', marginTop: 2 }} />
            </Tooltip>
          }
        />
        <CardContent>
          {toggles.map((toggle) => (
            <TogglesContent key={toggle.toggleId} toggle={toggle} />
          ))}
        </CardContent>
      </Card>
    </Box>
  );
};

export default TogglesView;
