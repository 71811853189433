import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getKombitCustomerHierarchy } from 'requests/kombitClient';

async function getCustomerHierarchy(cvrNumber: string) {
  return await getKombitCustomerHierarchy(cvrNumber);
}

export function useFetchKombitCustomer(cvrNumber: string): UseQueryResult<KombitTree> {
  return useQuery({
    queryKey: ['KombitCustomer', cvrNumber],
    queryFn: () => {
      const response = getCustomerHierarchy(cvrNumber);
      return response;
    },
    enabled: !!cvrNumber,
    gcTime: 15 * 60 * 1000,
    staleTime: Infinity,
  });
}

export interface KombitLocations {
  Id: string;
  Name: string;
  Children: KombitLocations[];
}

export interface KombitTree {
  CvrNumber: string;
  OrganisationTree: KombitLocations;
}
