import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';

type FormsStatusFilterProps = {
  statusOptions: string[];
  initialStatus: string;
  onStatusChanged: (status: string) => void;
};

function FormsStatusFilter({ statusOptions, initialStatus, onStatusChanged }: FormsStatusFilterProps) {
  const handleArchivedFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onStatusChanged(event.target.value);
  };

  return (
    <TextField
      style={{ width: '100%' }}
      label={'Status'}
      onChange={handleArchivedFilterChange}
      select
      SelectProps={{ native: true }}
      variant="outlined"
      defaultValue={initialStatus}
    >
      {statusOptions.map((so, i) => (
        <option key={i} value={so}>
          {so}
        </option>
      ))}
    </TextField>
  );
}
export default FormsStatusFilter;
