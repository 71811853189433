import { Authentication } from 'Authentication/Authentication';
import CurrentUserInformation from 'Authentication/CurrentUserInformation';
import { getSessionId } from 'requests/sessionId';
import { v4 as uuidv4 } from 'uuid';

export class BaseClient {
  async transformOptions(options: RequestInit): Promise<RequestInit> {
    options.headers = new Headers({
      'Content-Type': 'application/json;',
      Accept: 'application/json',
      Authorization: `Bearer ${await Authentication.Instance.GetToken()}`,
      'sekoia.session_id': getSessionId(),
      'sekoia.correlation_id': uuidv4(),
      'sekoia.customer_id': CurrentUserInformation.Instance.getUserInformation().customerId,
    });

    return options;
  }

  getBaseUrl(_: string, parsedBaseUrl: string | undefined): string {
    if (parsedBaseUrl) {
      return parsedBaseUrl;
    }

    if (!process.env.REACT_APP_NEXUS_BASE_URL)
      throw new Error('REACT_APP_NEXUS_SERVICE_URL must be set in environment');

    return process.env.REACT_APP_NEXUS_BASE_URL;
  }
}
