import { TableCell, TableRow, useTheme } from '@mui/material';
import React from 'react';
import { StandardFormLocation } from './FormsHelper';
import { useStandardFormContext } from './StandardFormProvider';
import StandardFormsTableFolders from './StandardFormsTableFolders';
import StandardFormsTableForms from './StandardFormsTableForms';

type StandardFormsTableLocationProps = {
  location: StandardFormLocation;
};

function StandardFormLocationRow({ location }: StandardFormsTableLocationProps) {
  const theme = useTheme();
  const { forms, folders } = useStandardFormContext();

  const filteredForms = forms?.filter((form) => form.locationId === location.id);
  const filteredFormsNoFolder = filteredForms?.filter((form) => !form.folderId);
  const filteredFolders = folders?.filter((folder) => folder.ownerId === location.id) ?? [];

  if (!filteredForms || (filteredForms.length < 1 && filteredFolders.length < 1)) return null;

  return (
    <>
      <TableRow
        sx={{
          backgroundColor:
            location.locationType === 'customer'
              ? theme.palette.grey[300]
              : location.locationType === 'organisation'
                ? theme.palette.grey[200]
                : theme.palette.grey[100],
        }}
      >
        <TableCell variant="head" width={60} />
        <TableCell variant="head" component="th" scope="row" colSpan={6}>
          {location.name} ({filteredForms ? filteredForms.length : 0})
        </TableCell>
      </TableRow>
      <StandardFormsTableFolders locationId={location.id} folders={filteredFolders} />
      <StandardFormsTableForms forms={filteredFormsNoFolder ?? []} />
    </>
  );
}

export default StandardFormLocationRow;
