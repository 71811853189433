import React, { ReactNode } from 'react';
import {
  useTheme,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import { StandardFormLocation } from 'Components/StandardForms/FormsHelper';
import { LocationTypes } from 'Components/RenderLocationIcon';

type LocationsSelectorRenderProps = {
  locations: StandardFormLocation[];
  selectedLocationsIds: string[];
  handleAllLocationsSelectedOrDeselected: () => void;
  handleSelect: ((event: SelectChangeEvent<string[]>, _child: ReactNode) => void) | undefined;
  singleSelector: boolean;
};

function LocationsSelectorRender({
  handleAllLocationsSelectedOrDeselected,
  locations,
  selectedLocationsIds,
  handleSelect,
  singleSelector,
}: LocationsSelectorRenderProps) {
  const theme = useTheme();

  function selectedValue(ids: unknown | string | string[]) {
    if (singleSelector) {
      const selectedId = ids as string[];
      return locations.find((location) => location.id === selectedId[0])?.name;
    } else {
      const selectedIds = ids as string[];
      if (selectedIds.length === locations.length) return 'All selected';
      else if (selectedIds.length === 0) return 'None selected';
      return `${selectedLocationsIds.length}/${locations.length} Selected`;
    }
  }

  return (
    <FormControl variant="outlined" style={{ width: 200 }}>
      <InputLabel style={{ backgroundColor: 'white', paddingLeft: 6, paddingRight: 6, left: -6 }} shrink>
        Location
      </InputLabel>
      <Select
        multiple={!singleSelector}
        displayEmpty
        style={{ textAlign: 'left', alignItems: 'right' }}
        value={selectedLocationsIds}
        onChange={handleSelect}
        renderValue={selectedValue}
        input={<OutlinedInput />}
        MenuProps={{
          variant: 'menu',
        }}
      >
        {!singleSelector && (
          <MenuItem
            key="ignore"
            onClick={handleAllLocationsSelectedOrDeselected}
            style={{
              paddingLeft: theme.spacing(2),
              backgroundColor: 'white',
              borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
            }}
          >
            <Checkbox
              checked={selectedLocationsIds.length === locations.length}
              indeterminate={selectedLocationsIds.length < locations.length && selectedLocationsIds.length > 0}
            />
            <ListItemText
              primary={'Locations'}
              secondary={`${selectedLocationsIds.length}/${locations.length} Selected`}
            />
          </MenuItem>
        )}

        {locations.map((l) => {
          const paddingLeftFactor =
            l.locationType === LocationTypes.organisation ? 1 : l.locationType === LocationTypes.department ? 2 : 0;
          return (
            <MenuItem
              key={l.id}
              value={l.id}
              style={{
                paddingLeft: 16 + 32 * paddingLeftFactor,
                paddingRight: theme.spacing(2),
                backgroundColor: 'white',
              }}
            >
              <Checkbox checked={selectedLocationsIds.indexOf(l.id) > -1} />
              <ListItemText primary={l.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default LocationsSelectorRender;
