import { Avatar, useTheme } from '@mui/material';
import { AccountBalance, Domain, Hotel, People } from '@mui/icons-material';
import React from 'react';

export enum LocationTypes {
  customer = 'customer',
  organisation = 'organisation',
  department = 'department',
  apartment = 'apartment',
}

type RenderLocationIconTypes = {
  type: LocationTypes | undefined;
  size?: 'small' | 'medium';
};

export default function RenderLocationIcon({ type, size = 'medium' }: RenderLocationIconTypes) {
  const theme = useTheme();

  if (!type) return null;
  return (
    <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
      {type === LocationTypes.customer && <AccountBalance fontSize={size} />}
      {type === LocationTypes.organisation && <Domain fontSize={size} />}
      {type === LocationTypes.department && <People fontSize={size} />}
      {type === LocationTypes.apartment && <Hotel fontSize={size} />}
    </Avatar>
  );
}
