import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Authentication } from './Authentication';
import { User } from 'oidc-client';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import LoadingScreen from '../Components/LoadingScreen';

interface IAuthContext {
  isAuthenticated: boolean;
  authenticating: boolean;
  logout: () => void;
  login: () => void;
  user: User | null;
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  authenticating: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  user: null,
});

export const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [authenticating, setAuthenticating] = useState(() => {
    return location.hash.includes('code');
  });
  const [user, setUser] = useState<User | null>(null);

  const userLoaded = useCallback((user: User) => {
    setUser(user);
    setIsAuthenticated(true);
    setAuthenticating(false);
  }, []);
  const userUnloaded = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
  }, []);

  const login = useCallback(() => {
    setAuthenticating(true);
    sessionStorage.setItem('returnUrl', location.pathname);
    Authentication.Instance.userManager.signinRedirect();
  }, [location.pathname]);

  const logout = useCallback(() => {
    Authentication.Instance.logout();
  }, []);

  useEffect(() => {
    if (location.search.includes('code')) {
      Authentication.Instance.authenticate().then((user) => {
        const returnUrl = sessionStorage.getItem('returnUrl');
        sessionStorage.removeItem('returnUrl');

        if (returnUrl) {
          history.replace(returnUrl);
        }
        setIsAuthenticated(true);

        if (user) {
          setUser(user);
        }

        setIsLoading(false);
      });
    } else {
      Authentication.Instance.userManager.getUser().then((user) => {
        if (user) {
          setUser(user);

          Authentication.Instance.setUserInfo().then(() => {
            setIsAuthenticated(true);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Authentication.Instance.userManager.events.addUserLoaded(userLoaded);
    Authentication.Instance.userManager.events.addUserUnloaded(userUnloaded);

    return () => {
      Authentication.Instance.userManager.events.removeUserLoaded(userLoaded);
      Authentication.Instance.userManager.events.removeUserUnloaded(userUnloaded);
    };
  }, [userLoaded, userUnloaded]);

  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      authenticating,
      user,
      login,
      logout,
    }),
    [authenticating, isAuthenticated, login, logout, user],
  );

  if (authenticating || isLoading) {
    return <LoadingScreen />;
  }

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
