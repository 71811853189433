import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CreateLocationRequest, Customer, Department, Organisation } from 'requests/nswag/atlas/AtlasClient';
import useCreateDepartment from 'requests/hooks/useCreateDepartment';
import useCreateOrganisation from 'requests/hooks/useCreateOrganisation';
import useCreateApartment from 'requests/hooks/useCreateApartment';

interface ICreateLoctionDialogProps {
  parentLocation: Customer | Organisation | Department;
  closeDialog: () => void;
  customerId: string;
}

export default function CreateLocationDialog(props: ICreateLoctionDialogProps) {
  const { parentLocation, closeDialog, customerId } = props;
  const [locationName, setLocationName] = useState('');
  const { mutateAsync: createDepartmentMutation, isPending: pendingDepartment } = useCreateDepartment();
  const { mutateAsync: createOrganisationMutation, isPending: pendingOrganisation } = useCreateOrganisation();
  const { mutateAsync: createApartmentMutation, isPending: pendingApartment } = useCreateApartment();
  const { enqueueSnackbar } = useSnackbar();

  function createLocation() {
    try {
      if (locationName !== '') {
        const createLocationRequest = new CreateLocationRequest({
          parentId: parentLocation.id,
          name: locationName,
        });
        if (parentLocation instanceof Customer) {
          createOrganisationMutation({
            createLocationRequest,
            customerId: customerId,
          });
        }
        if (parentLocation instanceof Organisation) {
          createDepartmentMutation({
            createLocationRequest,
            customerId: customerId,
          });
        }
        if (parentLocation instanceof Department) {
          createApartmentMutation({
            createLocationRequest,
            customerId: customerId,
          });
        }
        closeDialog();
      }
    } catch (error) {
      closeDialog();
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
      });
    }
  }

  return (
    <Dialog open>
      <DialogTitle>Create location</DialogTitle>
      <DialogContent>
        {pendingOrganisation || pendingDepartment || pendingApartment ? (
          <LinearProgress />
        ) : (
          <TextField
            onChange={(event) => setLocationName(event.currentTarget.value)}
            value={locationName}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            closeDialog();
            event.stopPropagation();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button onClick={createLocation} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
