import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GetAllCustomerSettings } from 'requests/authenticationClient';

async function getAllCustomerSettings() {
  return await GetAllCustomerSettings();
}

export function useFetchAllCustomerSettings(): UseQueryResult<CustomerSettings[]> {
  return useQuery({ queryKey: ['AllCustomerSettings'], queryFn: getAllCustomerSettings });
}

interface CustomerSettings {
  compassUrl: string;
  customerLegacyId: number;
}
