const emptyUserInfo = (): UserInformation => {
  return {
    id: 0,
    globalId: '',
    customerId: '',
    roles: [],
  };
};

export default class CurrentUserInformation {
  public static Instance = new CurrentUserInformation();
  private userInformation: UserInformation = emptyUserInfo();

  public getUserInformation(): UserInformation {
    return this.userInformation;
  }

  public setUserInformation(userInformation: UserInformation) {
    this.userInformation = userInformation;
  }
}

type UserInformation = {
  globalId: string;
  id: number;
  customerId: string;
  roles: string[];
};
