import { useSnackbar } from 'notistack';
import React, { useContext, useMemo } from 'react';
import { useFetchCustomer } from 'requests/hooks/useFetchCustomer';
import { useFetchAllToggles } from 'requests/hooks/useFetchAllToggles';
import { useFetchTogglesForCustomer } from 'requests/hooks/useFetchTogglesForCustomer';
import { useFetchNexusConfiguration } from 'requests/hooks/useFetchNexusConfiguration';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import { sortArrayOfObjects } from 'utils/sortHelpers';
import { CustomerToggleResponse, ToggleResponse } from 'requests/nswag/toggles/TogglesClient';
import { Customer, Organisation } from 'requests/nswag/atlas/AtlasClient';
import { ConfigurationResponse } from 'requests/nswag/nexus/NexusClient';
import LoadingScreen from 'Components/LoadingScreen';

interface TogglesContext {
  toggles: ToggleResponse[];
  customerLocation: Customer;
  customerToggles: CustomerToggleResponse[] | undefined;
  nexusConfiguration: ConfigurationResponse | undefined;
  organisations: Organisation[];
}

const TogglesContext = React.createContext<TogglesContext | null>(null);
export const useTogglesContext = () => {
  const togglesContext = useContext(TogglesContext);

  if (!togglesContext) {
    throw Error(`No TogglesConfigurationContext found!`);
  }
  return togglesContext;
};

export const TogglesProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();
  const customer = useCustomerContext();
  const {
    data: customerLocation,
    fetchStatus: fetchStatusCustomer,
    status: customerLocationStatus,
  } = useFetchCustomer(customer.id);
  const { data: toggles, fetchStatus: fetchStatusToggles, status: togglesStatus } = useFetchAllToggles();
  const { data: customerToggles, status: customerTogglesStatus } = useFetchTogglesForCustomer(customer.id);
  const { data: nexusConfiguration, status: nexusConfigurationStatus } = useFetchNexusConfiguration(customer.id);

  const sortedToggles = useMemo(() => sortArrayOfObjects(toggles ?? [], 'name'), [toggles]);

  const organisations = useMemo(() => {
    if (!customerLocation) return [];
    return sortArrayOfObjects(customerLocation.organisations, 'name');
  }, [customerLocation]);

  if (
    customerTogglesStatus === 'pending' ||
    fetchStatusCustomer === 'fetching' ||
    fetchStatusToggles === 'fetching' ||
    nexusConfigurationStatus === 'pending'
  ) {
    return <LoadingScreen />;
  }

  if (
    customerLocationStatus === 'error' ||
    togglesStatus === 'error' ||
    customerTogglesStatus === 'error' ||
    nexusConfigurationStatus === 'error'
  ) {
    enqueueSnackbar('Could not fetch toggles', {
      variant: 'error',
    });
  }

  if (!customerLocation) return null;
  return (
    <TogglesContext.Provider
      value={{
        toggles: sortedToggles,
        customerLocation,
        customerToggles,
        nexusConfiguration,
        organisations,
      }}
    >
      {children}
    </TogglesContext.Provider>
  );
};
