import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { SnackbarKey } from 'notistack';

interface ISnackbarClosebuttonProps {
  snackbarKey: SnackbarKey;
}

function SnackbarCloseButton(props: ISnackbarClosebuttonProps) {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button onClick={() => closeSnackbar(props.snackbarKey)} style={{ color: 'white' }}>
      Close
    </Button>
  );
}

export default SnackbarCloseButton;
