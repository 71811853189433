import { CustomerTogglesClient } from '../nswag/toggles/TogglesClient';
import { useQuery } from '@tanstack/react-query';
import CurrentUserInformation from 'Authentication/CurrentUserInformation';

async function getCustomerToggles(customerId: string) {
  const client = new CustomerTogglesClient();

  return client.getCustomerToggles(customerId, CurrentUserInformation.Instance.getUserInformation().customerId);
}

export function useFetchTogglesForCustomer(customerId: string) {
  return useQuery({ queryKey: ['TOGGLES', customerId.toLowerCase()], queryFn: () => getCustomerToggles(customerId) });
}
