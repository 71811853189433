import { Label } from '@mui/icons-material';
import React from 'react';

type StatusLabelProps = {
  archived: boolean;
};

function StatusLabel({ archived }: StatusLabelProps) {
  return <Label color={archived ? 'error' : 'success'}>{archived ? 'Archived' : 'Active'}</Label>;
}

export default React.memo(StatusLabel);
