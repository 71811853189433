import React, { useMemo } from 'react';
import { FormAssignmentResponseWithNameAndLocationType } from 'requests/hooks/useFetchStandardFormLocations';
import StandardFormsTableFormsFormRow from './StandardFormsTableFormsFormRow';

type StandardFormsTableFormsProps = {
  forms: FormAssignmentResponseWithNameAndLocationType[];
};

function StandardFormsTableForms({ forms }: StandardFormsTableFormsProps) {
  const formsSorted = useMemo(() => forms.sort((a, b) => a.title.localeCompare(b.title)), [forms]);

  if (formsSorted.length < 1) return null;

  return (
    <>
      {formsSorted.map((form) => (
        <StandardFormsTableFormsFormRow form={form} key={form.id} />
      ))}
    </>
  );
}

export default StandardFormsTableForms;
