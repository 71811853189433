import { Box, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { TableContainer } from '@mui/material';
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableBody } from '@mui/material';
import StandardFormLocations from './StandardFormLocations';
import { useStandardFormContext } from './StandardFormProvider';

function StandardFormsTable() {
  const theme = useTheme();
  const { forms, folders } = useStandardFormContext();

  const emptyState = useMemo(
    () => (
      <Typography align="center" variant={'h5'} color="textPrimary" style={{ padding: theme.spacing(3) }}>
        No forms
      </Typography>
    ),
    [theme],
  );

  if (!forms && !folders) return emptyState;

  return (
    <Box minWidth={700} height={'calc(100vh - 240px)'}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell width={120}>Status</TableCell>
              <TableCell width={100}>Chosen</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer style={{ maxHeight: 'calc(100vh - 290px)' }}>
        <Table stickyHeader>
          <TableBody>
            <StandardFormLocations />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default StandardFormsTable;
