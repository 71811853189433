import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LocationsInternalClient, UpdateExternalIdsRequest } from 'requests/nswag/atlas/AtlasClient';
import { useCallback } from 'react';

const useUpdateLocationExternalIds = () => {
  const queryClient = useQueryClient();

  const updateLocationExternalIds = useCallback(async (request: UpdateExternalIdsMutationRequest) => {
    const client = new LocationsInternalClient();
    return await client.batchUpdateExternalIds(request.request);
  }, []);

  return useMutation({
    mutationFn: updateLocationExternalIds,
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['CUSTOMER', request.customerId] });
    },
  });
};

export interface UpdateExternalIdsMutationRequest {
  request: UpdateExternalIdsRequest[];
  customerId: string;
}

export default useUpdateLocationExternalIds;
