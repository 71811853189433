import React from 'react';
import { TogglesProvider } from './TogglesProvider';
import TogglesView from './TogglesView';

const TogglesPage: React.FC = () => {
  return (
    <TogglesProvider>
      <TogglesView />
    </TogglesProvider>
  );
};

export default TogglesPage;
