import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Customer, LocationsInternalClient } from '../nswag/atlas/AtlasClient';

async function getCustomerTree(customerId?: string) {
  if (!customerId) return;
  const client = new LocationsInternalClient();
  return client.getCustomer(customerId, false);
}

export function useFetchCustomer(customerId?: string): UseQueryResult<Customer> {
  return useQuery({
    queryKey: ['CUSTOMER', customerId],
    queryFn: () => getCustomerTree(customerId),
    enabled: !!customerId,
    gcTime: 15 * 60 * 1000, // 15 minutes,
    staleTime: Infinity,
  });
}
