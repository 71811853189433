import { useEffect, useState } from 'react';
import useFetchStandardFormLocations, {
  FormAssignmentResponseWithNameAndLocationType,
} from './useFetchStandardFormLocations';
import useFetchStandardForms from './useFetchStandardForms';
import { deepEqual } from 'fast-equals';
import { StandardFormLocation } from 'Components/StandardForms/FormsHelper';

type useMapFormWithLocationNameReturn = {
  error: boolean;
  isLoading: boolean;
  isFetched: boolean;
  data: FormAssignmentResponseWithNameAndLocationType[] | undefined;
};

export const useMapFormWithLocationName = (customerId: string) => {
  const formLocations = useFetchStandardFormLocations(customerId);

  const standardForms = useFetchStandardForms(customerId, formLocations.data?.locationIds ?? []);

  const [fetchMapFormWithLocationNameReturn, setFetchMapFormWithLocationNameReturn] =
    useState<useMapFormWithLocationNameReturn>({
      error: false,
      isLoading: true,
      isFetched: false,
      data: undefined,
    });

  useEffect(() => {
    const nextMapFormWithLocationNameReturn = Object.assign({}, fetchMapFormWithLocationNameReturn);
    if (formLocations.data && standardForms.data) {
      const mappedFormsWithLocationNameAndType: FormAssignmentResponseWithNameAndLocationType[] =
        standardForms.data.map((form) => {
          const locationWithIdMatchingFormOwnerId: StandardFormLocation | undefined = formLocations.data.locations.find(
            (l) => l.id === form.ownerId,
          );
          return {
            archived: form.archived,
            id: form.id,
            ownerId: form.ownerId,
            title: form.title,
            locationName: locationWithIdMatchingFormOwnerId?.name,
            locationType: locationWithIdMatchingFormOwnerId?.locationType,
            locationId: locationWithIdMatchingFormOwnerId?.id,
            accessControlTagIds: form.accessControlTagIds,
            folderId: form.folderId,
          };
        });
      Object.defineProperty(nextMapFormWithLocationNameReturn, 'data', {
        value: mappedFormsWithLocationNameAndType,
        enumerable: true,
      });
    }
    nextMapFormWithLocationNameReturn.isFetched = formLocations.isFetched && standardForms.isFetched;
    nextMapFormWithLocationNameReturn.isLoading = formLocations.isLoading || standardForms.isLoading;
    nextMapFormWithLocationNameReturn.error = formLocations.isError || standardForms.isError;
    if (!deepEqual(nextMapFormWithLocationNameReturn, fetchMapFormWithLocationNameReturn)) {
      setFetchMapFormWithLocationNameReturn(nextMapFormWithLocationNameReturn);
    }
  }, [
    fetchMapFormWithLocationNameReturn,
    formLocations.data,
    formLocations.isError,
    formLocations.isFetched,
    formLocations.isLoading,
    standardForms.data,
    standardForms.isError,
    standardForms.isFetched,
    standardForms.isLoading,
  ]);
  return fetchMapFormWithLocationNameReturn;
};

export default useMapFormWithLocationName;
