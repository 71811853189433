import CreateLocationDialog from 'Components/CreateLocationDialog';
import DeleteLocationDialog from 'Components/DeleteLocationDialog';
import RenameLocationDialog from 'Components/RenameLocationDialog';
import React, { useContext, useState } from 'react';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import { useFetchCustomer } from 'requests/hooks/useFetchCustomer';
import { Apartment, Customer, Department, Organisation } from 'requests/nswag/atlas/AtlasClient';

interface LocationContext {
  customer: Customer;
  setDeleteLocation: (location: Organisation | Department | Apartment) => void;
  setRenameLocation: (location: Organisation | Department | Apartment) => void;
  setCreateLocation: (location: Customer | Organisation | Department) => void;
}

export const LocationContext = React.createContext<LocationContext | null>(null);
export const useLocationContext = () => {
  const locationContext = useContext(LocationContext);

  if (!locationContext) {
    throw Error(`Empty context!`);
  }

  return locationContext;
};

export const LocationProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const customer = useCustomerContext();
  const { data } = useFetchCustomer(customer.id);
  const [deleteLocation, setDeleteLocation] = useState<Organisation | Department | Apartment | undefined>(undefined);
  const [renameLocation, setRenameLocation] = useState<Organisation | Department | Apartment | undefined>(undefined);
  const [createLocation, setCreateLocation] = useState<Customer | Organisation | Department | undefined>(undefined);

  if (!data) return null;

  return (
    <LocationContext.Provider
      value={{
        customer: data,
        setDeleteLocation,
        setRenameLocation,
        setCreateLocation,
      }}
    >
      {props.children}
      {deleteLocation && (
        <DeleteLocationDialog
          location={deleteLocation}
          closeDialog={() => setDeleteLocation(undefined)}
          customerId={customer.id}
        />
      )}
      {renameLocation && (
        <RenameLocationDialog location={renameLocation} closeDialog={() => setRenameLocation(undefined)} />
      )}
      {createLocation && (
        <CreateLocationDialog
          parentLocation={createLocation}
          closeDialog={() => setCreateLocation(undefined)}
          customerId={customer.id}
        />
      )}
    </LocationContext.Provider>
  );
};
