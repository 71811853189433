import { User, UserManager, UserManagerSettings } from 'oidc-client';
import IEnvironmentInformation from './IEnvironmentInformation';
import JWTTokenDecoder from './jwtTokenDecoder';
import * as fetchIntercept from 'fetch-intercept';
import { FetchInterceptorResponse } from 'fetch-intercept';
import CurrentUserInformation from './CurrentUserInformation';

export const sekoiaHostnameRegexp = /\.sekoia\.one$|\.sekoia\.dk$|\.sekoia.\se$|\.sekoia\.co\.uk$/g;
export class Authentication {
  private _environmentInformation: IEnvironmentInformation;
  public userManager: UserManager;
  private _jwtTokenDecoder: JWTTokenDecoder;
  public static Instance = new Authentication();

  constructor() {
    if (!process.env.REACT_APP_BASE_AUTH_URL) {
      throw new Error();
    }
    this._environmentInformation = {
      BaseAuthUrl: process.env.REACT_APP_BASE_AUTH_URL,
    };
    this._jwtTokenDecoder = new JWTTokenDecoder();
    this.userManager = new UserManager(this.getClientSettings());

    this.userManager.events.addUserLoaded(async () => {
      await this.setUserInfo();
    });

    // Intercept fetch 401
    fetchIntercept.register({
      response(response: FetchInterceptorResponse): FetchInterceptorResponse {
        const responseUrl = new URL(response.url);
        if (response.status === 401 && responseUrl.hostname.match(sekoiaHostnameRegexp)) {
          Authentication.Instance.login();
        }

        return response;
      },
    });
  }

  public async setUserInfo(): Promise<void> {
    const token = await this.GetToken();
    let userRoles;

    if (!token || token === '') return;

    const {
      nameid: globalId,
      unique_name: legacyId,
      role: roles,
      'sekoia:CustomerId': customerId,
    } = this._jwtTokenDecoder.decodeToken(token);

    if (Array.isArray(roles)) userRoles = roles.filter((role: string) => !parseInt(role)) as string[];
    else userRoles = [roles as string];
    const userInformation = {
      globalId,
      id: parseInt(legacyId),
      customerId,
      roles: userRoles,
    };

    CurrentUserInformation.Instance.setUserInformation(userInformation);
  }

  public async authenticate(): Promise<User | null> {
    const url = window.location.href;
    try {
      await this.userManager.signinRedirectCallback(url);
      const profile = await this.userManager.getUser();

      if (!profile) {
        await this.logout();
      }

      return profile;
    } catch (e) {
      await this.userManager.clearStaleState();
      window.sessionStorage.clear();
      window.location.replace(`${window.location.protocol}//${window.location.host}`);
    }

    return null;
  }

  public login(): void {
    this.userManager.signinRedirect();
  }

  public async logout(): Promise<void> {
    this.userManager.stopSilentRenew();
    this.userManager.clearStaleState();
    try {
      await this.userManager.removeUser();
    } catch (error) {
      window.sessionStorage.clear();
    }

    const signoutUrl = await this.userManager.metadataService.getEndSessionEndpoint();
    if (signoutUrl === undefined) {
      throw Error('SignoutUrl is undefined');
    }

    await fetch(signoutUrl, {
      credentials: 'include',
    });

    const postLogoutRedirectUri = this.getClientSettings().post_logout_redirect_uri;
    if (postLogoutRedirectUri === undefined) {
      throw Error('SignoutUrl is undefined');
    }

    window.location.replace(postLogoutRedirectUri);
  }

  public async isAuthenticated(): Promise<boolean> {
    const user = await this.userManager.getUser();

    const loggedIn = !(!user || !user.access_token) && !user.expired;

    return loggedIn;
  }

  public async GetToken(): Promise<string> {
    const user = await this.userManager.getUser();
    if (!user) return '';
    return user.access_token;
  }

  public stopSilentRefresh(): void {
    this.userManager.stopSilentRenew();
  }

  public startSilentRefresh(): void {
    this.userManager.startSilentRenew();
  }

  private getClientSettings(): UserManagerSettings {
    return {
      authority: this._environmentInformation.BaseAuthUrl,
      metadata: {
        authorization_endpoint: this._environmentInformation.BaseAuthUrl + '/connect/authorize',
        end_session_endpoint: this._environmentInformation.BaseAuthUrl + '/connect/endsession',
        token_endpoint: this._environmentInformation.BaseAuthUrl + '/connect/token',
      },
      accessTokenExpiringNotificationTime: 180,
      automaticSilentRenew: true,
      client_id: 'customerConfiguration',
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/`,
      redirect_uri: `${window.location.protocol}//${window.location.host}/login.html`,
      response_type: 'code',
      scope: 'api offline_access IdentityServerApi',
      silent_redirect_uri: `${window.location.protocol}//${window.location.host}/login.html`,
      clockSkew: 30,
    };
  }
}
