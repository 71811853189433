import React, { useMemo, useState } from 'react';
import { Accordion, AccordionDetails, Box, FormControlLabel, Stack, styled, Switch, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ToggleCustomerFeatureRequest,
  ToggleOrganisationFeatureRequest,
  ToggleResponse,
} from 'requests/nswag/toggles/TogglesClient';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useTogglesContext } from './TogglesProvider';
import useUpdateCustomerToggle from 'requests/hooks/useUpdateCustomerToggle';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import TogglesOrganisations from './TogglesOrganisations';

type TogglesContent = {
  toggle: ToggleResponse;
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const TogglesContent: React.FC<TogglesContent> = (props) => {
  const { toggle } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerToggles, customerLocation } = useTogglesContext();
  const customerToggleMutation = useUpdateCustomerToggle();
  const [expand, setExpand] = useState(false);
  const [processing, setIsProcessing] = useState(false);
  const [isEnabledForCustomer, setIsEnabledForCustomer] = useState(false);

  const customerToggle = useMemo(() => {
    const ct = customerToggles?.find((ct) => ct.toggleId === toggle.toggleId);
    setIsEnabledForCustomer(ct ? ct.isEnabledForCustomer : false);
    return ct;
  }, [customerToggles, toggle.toggleId]);

  const updateCustomerClientToggle = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (!customerToggle) return;
    setIsProcessing(true);
    const newState = !isEnabledForCustomer;
    setIsEnabledForCustomer(newState);
    const organisationToggles = customerToggle.featureEnabledForOrganisations.map(
      (orgToggle) =>
        new ToggleOrganisationFeatureRequest({
          customerId: customerLocation.id,
          organisationId: orgToggle.organisationId.toLowerCase(),
          isEnabled: orgToggle.isEnabled,
        }),
    );

    const request = new ToggleCustomerFeatureRequest({
      customerId: customerLocation.id,
      toggleId: toggle.toggleId,
      isEnabled: newState,
      organisationToggles: organisationToggles,
    });

    try {
      await customerToggleMutation.mutateAsync(request);
    } catch (error) {
      enqueueSnackbar('Something went wrong trying to update toggles', {
        variant: 'error',
      });
      setIsEnabledForCustomer(!newState);
    } finally {
      setIsProcessing(false);
    }
  };

  if (!customerToggle) return null;

  return (
    <Accordion expanded={expand} slotProps={{ transition: { unmountOnExit: true } }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '& .MuiAccordionSummary-content': { display: 'block', margin: 0, marginLeft: 1 },
          '& .MuiAccordionSummary-content.Mui-expanded': { display: 'block', margin: 0, marginLeft: 1 },
        }}
        onClick={() => setExpand(!expand)}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="body1" component={'span'}>
              {toggle.name}
            </Typography>
          </Box>
          <Stack direction={'row'} alignItems={'center'}>
            <FormControlLabel
              sx={{ whiteSpace: 'nowrap' }}
              control={
                processing ? (
                  <Box width={58}>
                    <CircularProgress size={'30px'} sx={{ mr: 1 }} />
                  </Box>
                ) : (
                  <Switch
                    color={isEnabledForCustomer ? 'primary' : 'secondary'}
                    checked={isEnabledForCustomer}
                    onClick={updateCustomerClientToggle}
                  />
                )
              }
              labelPlacement="start"
              label="CLIENT LEVEL"
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Typography variant="body2" sx={{ p: 2 }}>
          {toggle.friendlyName}
        </Typography>
        <TogglesOrganisations customerToggle={customerToggle} />
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(TogglesContent);
