import { TableRow, TableCell } from '@mui/material';
import LocationSingleOrganisationsSelectorWithSearch from 'Components/LocationsSelector/LocationSingleOrganisationsSelectorWithSearch';
import React, { useCallback, useMemo } from 'react';
import { KombitLocations } from 'requests/hooks/useFetchKombitCustomer';
import { Customer } from 'requests/nswag/atlas/AtlasClient';

type KombitLocationTreeProps = {
  kombitLocation: KombitLocations;
  indent: number;
  customerId: string;
  onSelectedLocation: (sekoiaLlocationId: string, kombitLocationId: string) => void;
  sekoiaLocations: Customer | undefined;
};

const KombitLocationTree: React.FC<KombitLocationTreeProps> = (props) => {
  const { kombitLocation, indent, customerId, onSelectedLocation, sekoiaLocations } = props;

  const handleSelectedLocation = useCallback(
    (sekoiaLocationId: string) => {
      onSelectedLocation(sekoiaLocationId, kombitLocation.Id);
    },
    [kombitLocation.Id, onSelectedLocation],
  );

  const selectedLocation = useMemo(() => {
    return sekoiaLocations?.organisations.find((org) => org.externalIds.includes(kombitLocation.Id));
  }, [kombitLocation.Id, sekoiaLocations]);

  return (
    <>
      <TableRow>
        <TableCell style={{ paddingLeft: indent * 16, backgroundColor: undefined }}>{kombitLocation.Name}</TableCell>
        <TableCell width={'50%'}>
          <LocationSingleOrganisationsSelectorWithSearch
            onSelectedLocation={handleSelectedLocation}
            location={sekoiaLocations?.organisations ?? []}
            selectedLocation={selectedLocation ?? ''}
          />
        </TableCell>
      </TableRow>
      {kombitLocation.Children.map((location) => {
        return (
          <KombitLocationTree
            kombitLocation={location}
            indent={indent + 1}
            key={location.Id}
            customerId={customerId}
            onSelectedLocation={onSelectedLocation}
            sekoiaLocations={sekoiaLocations}
          />
        );
      })}
    </>
  );
};

export default KombitLocationTree;
