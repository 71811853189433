import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useCallback } from 'react';
import { StandardTaskRestEntity, StandardTasksClient } from 'requests/nswag/tasks/TasksClient';

function useFetchStandardTasks(organisationId: string): UseQueryResult<StandardTaskRestEntity[]> {
  const getStandardTasks = useCallback(() => {
    const tasksClient = new StandardTasksClient();
    return tasksClient.getByOrganisation(organisationId, false, undefined, undefined, undefined, undefined, undefined);
  }, [organisationId]);

  return useQuery({ queryKey: ['STANDARD_TASKS', organisationId], queryFn: getStandardTasks });
}

export default useFetchStandardTasks;
