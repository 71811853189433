import { Authentication } from 'Authentication/Authentication';
import axios from 'axios';
import { getSessionId } from './sessionId';
import { v4 as uuidv4 } from 'uuid';

const client = axios.create({
  baseURL: process.env.REACT_APP_KOMBIT_BASE_URL,
  headers: {
    'Content-Type': 'application/json;',
    Accept: 'application/json',
    'sekoia.session_id': getSessionId(),
  },
});

export async function getKombitCustomerHierarchy(cvrNumber: string) {
  const response = await client.get(`/api/organisation/${cvrNumber}/hierarchy`, {
    headers: { Authorization: `Bearer ${await Authentication.Instance.GetToken()}`, 'sekoia.correlation_id': uuidv4() },
  });
  return response.data;
}
