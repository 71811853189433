import React, { useMemo } from 'react';
import { FolderResponseWithChecked } from 'requests/hooks/useFetchFormsFolders';
import StandardFormsTableFormsFolderRow from './StandardFormsTableFormsFolderRow';

type StandardFormsTableFoldersProps = {
  locationId: string;
  folders: FolderResponseWithChecked[];
};

function StandardFormsTableFolders({ locationId, folders }: StandardFormsTableFoldersProps) {
  const filteredFolders = useMemo(
    () => folders.filter((folder) => folder.ownerId === locationId).sort((a, b) => a.name.localeCompare(b.name)),
    [folders, locationId],
  );

  if (filteredFolders.length < 0) return null;

  return (
    <>
      {filteredFolders.map((folder) => (
        <StandardFormsTableFormsFolderRow folder={folder} key={folder.id} />
      ))}
    </>
  );
}

export default StandardFormsTableFolders;
