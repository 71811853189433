import { Authentication } from 'Authentication/Authentication';
import { getSessionId } from 'requests/sessionId';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_AUTH_URL,
  headers: {
    'Content-Type': 'application/json;',
    Accept: 'application/json',
    'sekoia.session_id': getSessionId(),
  },
});

export async function GetAllCustomerSettings() {
  const response = await client.get('/api/customerSettings', {
    headers: { Authorization: `Bearer ${await Authentication.Instance.GetToken()}`, 'sekoia.correlation_id': uuidv4() },
  });
  return response.data;
}
