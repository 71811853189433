import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LocationsInternalClient } from '../nswag/atlas/AtlasClient';

export default function useDeleteDepartment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: IDeleteDepartmentRequest) => {
      const client = new LocationsInternalClient();
      return await client.deleteDepartment(request.departmentId);
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['CUSTOMER', request.customerId] });
    },
  });
}

interface IDeleteDepartmentRequest {
  departmentId: string;
  customerId: string;
}
