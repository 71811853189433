import React, { ReactNode, useMemo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useFetchCustomers } from '../requests/hooks/useFetchCustomers';
import { useFetchAllCustomerSettings } from '../requests/hooks/useFetchAllCustomerSettings';
import { CustomerResponse } from '../requests/nswag/atlas/AtlasClient';
import { ExtendedCustomerResponse } from './Homepage';
import { sortArrayOfObjects } from '../utils/sortHelpers';

type CustomerSelectProps = {
  customer: ExtendedCustomerResponse | null;
  setCustomer: (customer: ExtendedCustomerResponse) => void;
  color?: string;
};

export default function CustomerSelect(props: CustomerSelectProps) {
  const { customer, setCustomer, color = '#FFFFFF' } = props;

  const { data: customers } = useFetchCustomers();
  const { data: allCustomerSettings } = useFetchAllCustomerSettings();

  const sortedCustomers = useMemo(() => sortArrayOfObjects(customers ?? [], 'name'), [customers]);

  const handleChange = (event: SelectChangeEvent<string[]>, _child: ReactNode) => {
    if (customers && allCustomerSettings) {
      const customerId = event.target.value as string;
      const selectedCustomer = customers.find((x) => x.id === customerId);
      let extendedCustomer: ExtendedCustomerResponse | null = null;

      if (selectedCustomer) {
        const compassUrl = allCustomerSettings.find(
          (x) => x.customerLegacyId === selectedCustomer.legacyId,
        )?.compassUrl;
        extendedCustomer = {
          name: selectedCustomer.name,
          id: selectedCustomer.id,
          compassUrl: compassUrl ? compassUrl : '',
        };
      }

      if (extendedCustomer) setCustomer(extendedCustomer);
    }
  };

  return (
    <FormControl
      sx={{
        margin: 1,
        minWidth: 120,
        '& .MuiFormLabel-root': {
          color: color,
        },
        '& .MuiInputBase-root': {
          color: color,
        },
      }}
      variant="standard"
    >
      <InputLabel id="customer-select-label">Customer</InputLabel>
      <Select
        value={customer ? [customer.id] : ''}
        onChange={handleChange}
        sx={{
          '&:before': {
            borderColor: color,
          },
          '&:after': {
            borderColor: color,
          },
          '& .MuiSelect-icon': {
            color: color,
          },
        }}
        label={'Customer'}
        labelId={'customer-select-label'}
      >
        {sortedCustomers.map((customer: CustomerResponse) => (
          <MenuItem value={customer.id} key={customer.id}>
            {customer.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
