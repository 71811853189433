/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';

const guid = uuidv4();

const GetSessionId = () => {
  return guid;
};

export const getSessionId = GetSessionId;
