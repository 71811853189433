import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import useMapFormWithLocationName from 'requests/hooks/useMapFormWithLocationName';
import StandardFormsTable from './StandardFormsTable';
import FormsSearch from './FormsSearch';
import useFetchStandardFormLocations, {
  FormAssignmentResponseWithNameAndLocationType,
} from 'requests/hooks/useFetchStandardFormLocations';
import LoadingScreen from 'Components/LoadingScreen';
import FormsStatusFilter from './FormsStatusFilter';
import CopyDialog from './CopyDialog';
import LocationsSelector from 'Components/LocationsSelector/LocationsSelector';
import { StandardFormProvider } from './StandardFormProvider';
import useFetchFormsFolders, { FolderResponseWithChecked } from 'requests/hooks/useFetchFormsFolders';

function StandardFormsView() {
  const theme = useTheme();
  const customer = useCustomerContext();
  const { data: forms, isFetched, isLoading } = useMapFormWithLocationName(customer.id);
  const {
    data: folders,
    status: fetchFoldersStatus,
    fetchStatus: fetchFoldersFetchStatus,
  } = useFetchFormsFolders(customer.id);
  const [formsFiltered, setFormsFiltered] = useState<FormAssignmentResponseWithNameAndLocationType[]>();
  const [processedForms, setProcessedForms] = useState<FormAssignmentResponseWithNameAndLocationType[] | undefined>(
    undefined,
  );
  const [processedFolders, setProcessedFolders] = useState<FolderResponseWithChecked[] | undefined>(undefined);
  const [foldersFiltered, setFoldersFiltered] = useState<FolderResponseWithChecked[]>();

  const locations = useFetchStandardFormLocations(customer.id);
  const [selectedForms, setSelectedForms] = useState<string[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);

  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('Active');

  const statusFilterOptions = ['All', 'Active', 'Archived'];

  useEffect(() => {
    if (customer) {
      setSelectedLocations(locations.data?.locationIds ?? []);
    }
  }, [customer, locations.data?.locationIds]);

  useEffect(() => {
    if (customer) {
      setSelectedForms([]);
      setSelectedFolders([]);
    }
  }, [customer]);

  useEffect(() => {
    const processForms: FormAssignmentResponseWithNameAndLocationType[] = [];
    if (isLoading) return;
    const formsFilteredId = formsFiltered?.map((f) => f.id);
    forms?.forEach((form) => {
      if (formsFilteredId?.includes(form.id)) {
        form.isChecked = selectedForms.some((f) => f === form.id);
        if (form.locationId && selectedLocations.includes(form.locationId)) processForms.push(form);
      }
    });
    if (isFetched && formsFiltered !== undefined) {
      setProcessedForms(
        processForms.filter((f) => {
          if (statusFilter === 'Active') return !f.archived;
          if (statusFilter === 'Archived') return f.archived;
          return f;
        }),
      );
    }
  }, [forms, formsFiltered, isFetched, isLoading, selectedForms, selectedLocations, statusFilter]);

  useEffect(() => {
    const processFolders: FolderResponseWithChecked[] = [];
    if (fetchFoldersFetchStatus === 'fetching') return;
    const foldersFilteredId = foldersFiltered?.map((f) => f.id);
    folders?.forEach((folder) => {
      if (foldersFilteredId?.includes(folder.id)) {
        folder.isChecked = selectedFolders.some((f) => f === folder.id);
        if (folder.ownerId && selectedLocations.includes(folder.ownerId)) processFolders.push(folder);
      }
    });
    if (fetchFoldersStatus === 'success') {
      setProcessedFolders(processFolders);
    }
  }, [fetchFoldersStatus, folders, foldersFiltered, selectedFolders, selectedLocations, fetchFoldersFetchStatus]);

  useEffect(() => {
    if (forms) setFormsFiltered(forms);
  }, [forms]);

  useEffect(() => {
    if (folders) setFoldersFiltered(folders);
  }, [folders]);

  const handleSelectedForms = (id: string) => {
    const selectedFormsClone = Array.from(selectedForms);
    const index = selectedForms.findIndex((fid) => fid === id);
    if (index > -1) {
      selectedFormsClone.splice(index, 1);
    } else {
      selectedFormsClone.push(id);
    }
    setSelectedForms(selectedFormsClone);
  };

  const handleSelectedFolders = (id: string) => {
    const selectedFoldersClone = Array.from(selectedFolders);
    const index = selectedFolders.findIndex((fid) => fid === id);
    if (index > -1) {
      selectedFoldersClone.splice(index, 1);
    } else {
      selectedFoldersClone.push(id);
    }
    setSelectedFolders(selectedFoldersClone);
  };

  if (isLoading || fetchFoldersFetchStatus === 'fetching') return <LoadingScreen />;

  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={12}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h5" component="span">
                  Standard forms
                </Typography>
              </Grid>
              <Grid item xs={6} container justifyContent={'flex-end'}>
                <Typography variant="h5" component="span">
                  <CopyDialog selectedFormIds={selectedForms} selectedFolderIds={selectedFolders} />
                </Typography>
              </Grid>
              <Grid item xs>
                <FormsSearch forms={forms ? forms : []} onFormsFiltered={setFormsFiltered} searchKey={'title'} />
              </Grid>
              <Grid item container xs justifyContent={'flex-end'}>
                <Grid item style={{ paddingRight: theme.spacing(2) }}>
                  <FormsStatusFilter
                    statusOptions={statusFilterOptions}
                    initialStatus={statusFilter}
                    onStatusChanged={setStatusFilter}
                  />
                </Grid>
                <Grid item>
                  <LocationsSelector
                    selectedLocationsIds={selectedLocations}
                    onSelectedLocationsChange={setSelectedLocations}
                    locations={locations.data?.locations ?? []}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StandardFormProvider
            forms={processedForms}
            folders={processedFolders ?? []}
            locations={locations.data?.locations ?? []}
            selectedLocationsIds={selectedLocations}
            selectedFolderIds={selectedFolders}
            setSelectedForm={handleSelectedForms}
            setSelectedFolder={handleSelectedFolders}
          >
            <StandardFormsTable />
          </StandardFormProvider>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StandardFormsView;
