import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CustomerContext } from './Homepage';

const CustomerNotSelectedErrorView: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { customer } = useContext(CustomerContext);

  if (customer) return <div style={{ width: 'inherit' }}>{props.children}</div>;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: 'calc(100vh - 64px)' }}
    >
      <Typography align="center" variant={'h6'} color="textPrimary">
        Select customer
      </Typography>
    </Grid>
  );
};

CustomerNotSelectedErrorView.propTypes = {
  children: PropTypes.any,
};

export default CustomerNotSelectedErrorView;
