import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Apartment } from 'requests/nswag/atlas/AtlasClient';
import { useLocationContext } from './Location/LocationProvider';

type ApartmentDotMenuProps = {
  apartment: Apartment;
};

function ApartmentDotMenu({ apartment }: ApartmentDotMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<(EventTarget & Element) | null>(null);

  const { setDeleteLocation, setRenameLocation } = useLocationContext();

  function onMenuClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onMenuClose() {
    setMenuAnchorEl(null);
  }

  const open = Boolean(menuAnchorEl);
  return (
    <>
      <IconButton style={{ padding: 0 }} onClick={onMenuClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setRenameLocation(apartment);
            onMenuClose();
          }}
        >
          <Typography>Rename apartment</Typography>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setDeleteLocation(apartment);
            onMenuClose();
          }}
        >
          <Typography>Delete apartment</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default ApartmentDotMenu;
