import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, LinearProgress, List, ListItem, ListItemText } from '@mui/material';
import useFetchStandardTasks from 'requests/hooks/useFetchStandardTasks';
import { sortArrayOfObjects } from 'utils/sortHelpers';
import useCreateStandardTask from 'requests/hooks/useCreateStandardTask';
import { CreateStandardTaskRequest } from 'requests/nswag/tasks/TasksClient';

import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

type StandardTasksSourceProps = {
  sourceOrganisationId: string;
  targetOrganisationId?: string;
};

const StandardTasksSource: React.FC<StandardTasksSourceProps> = (props) => {
  const queryClient = useQueryClient();
  const { sourceOrganisationId, targetOrganisationId } = props;
  const { data: standardTasks, fetchStatus } = useFetchStandardTasks(sourceOrganisationId);
  const createStandardTaskMutation = useCreateStandardTask();
  const [checked, setChecked] = React.useState<string[]>([]);
  const [toggleAll, setToggleAll] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (createStandardTaskMutation.status === 'success') {
      queryClient.invalidateQueries({ queryKey: ['STANDARD_TASKS', targetOrganisationId] });
    }
  }, [createStandardTaskMutation.status, queryClient, targetOrganisationId]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = () => {
    setToggleAll(!toggleAll);
  };

  const copyStandardTasks = async () => {
    let tasksIdsToCopy: string[] = checked;
    if (!targetOrganisationId) return;
    if (toggleAll && standardTasks) {
      tasksIdsToCopy = standardTasks?.map((t) => t.id);
    }

    tasksIdsToCopy.forEach(async (taskId) => {
      const currentTask = standardTasks?.find((t) => t.id === taskId);

      if (currentTask) {
        const healtAct = currentTask.categoryIds.indexOf('HealthAct') !== -1;
        const categoryIds: string[] = [];
        if (healtAct) categoryIds.push('HealthAct');
        try {
          createStandardTaskMutation.mutate({
            request: new CreateStandardTaskRequest({
              accessControlTagIds: currentTask.accessControlTagIds,
              canCancel: currentTask.canCancel,
              canCreateAdHoc: currentTask.canCreateAdHoc,
              canMove: currentTask.canMove,
              categoryIds: categoryIds,
              description: currentTask.description,
              durationInMinutes: currentTask.durationInMinutes,
              isContactPersonRelated: currentTask.isContactPersonRelated,
              isMedicationRelated: currentTask.isMedicationRelated,
              professionId: Number(currentTask.professionId),
              requiresTwoWorkers: currentTask.requiresTwoWorkers,
              tagIds: [],
              title: currentTask.title,
            }),
            organisationId: targetOrganisationId,
          });
        } catch {
          enqueueSnackbar(`Error copy: ${currentTask.title}`, {
            variant: 'error',
          });
        }
      }
    });
  };

  if (fetchStatus === 'fetching') return null;

  return (
    <Box m={2}>
      <Button
        variant="contained"
        fullWidth
        onClick={copyStandardTasks}
        disabled={createStandardTaskMutation.status === 'pending' || !targetOrganisationId}
      >
        Copy tasks
      </Button>
      {createStandardTaskMutation.status === 'pending' && <LinearProgress />}
      <List sx={{ width: '100%' }}>
        <ListItem
          secondaryAction={<Checkbox edge="end" onChange={handleToggleAll} checked={toggleAll} />}
          disablePadding
        >
          <ListItemText primary={'Select all'} primaryTypographyProps={{ style: { fontWeight: 800 } }} />
        </ListItem>
        {sortArrayOfObjects(standardTasks ?? [], 'title').map((task) => {
          return (
            <ListItem
              key={task.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggle(task.id)}
                  checked={toggleAll === true ? true : checked.indexOf(task.id) !== -1}
                  inputProps={{ 'aria-labelledby': task.id }}
                  disabled={toggleAll}
                />
              }
              disablePadding
            >
              <ListItemText primary={task.title} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default StandardTasksSource;
