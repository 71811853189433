import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Typography, CircularProgress, useTheme } from '@mui/material';
import useIsMounted from 'requests/hooks/useIsMounted';
import CheckIcon from '@mui/icons-material/Check';

type SaveDrawerProps = {
  open: boolean;
  onSave: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  isSaving: boolean;
  disableSaveButton?: boolean;
  modified?: boolean;
  hideCheckIcon?: boolean;
};

const SaveDrawer: React.FC<SaveDrawerProps> = (props) => {
  const { open, onSave, isSaving = false, onCancel, onClose, disableSaveButton, modified, hideCheckIcon } = props;

  const theme = useTheme();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [savingIsDone, setSavingIsDone] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isSaving) {
      setSavingInProgress(isSaving);
      setSavingIsDone(false);
    } else {
      setSavingIsDone(true);
      setTimeout(() => {
        if (isMounted()) setSavingInProgress(isSaving);
      }, 2000);
    }
  }, [isMounted, isSaving]);

  const progressRender = () => {
    if (savingIsDone && !hideCheckIcon)
      return <CheckIcon style={{ marginRight: theme.spacing(2) }} color={'primary'} />;
    return <CircularProgress size={32} style={{ marginRight: theme.spacing(2) }} />;
  };

  return (
    <Drawer
      anchor={'bottom'}
      open={open || savingInProgress}
      variant="persistent"
      sx={{
        '& .MuiDrawer-paper': {
          left: 202,
        },
      }}
      data-testid="saveDrawer"
    >
      <Box p={2} display="flex" justifyContent="flex-end" alignItems="center">
        {savingInProgress || !open
          ? progressRender()
          : !onCancel &&
            modified && (
              <Typography variant={'caption'} style={{ paddingRight: theme.spacing(2) }}>
                You have unsaved changes
              </Typography>
            )}
        {onCancel && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={onCancel}
            disabled={savingInProgress || !open}
            style={{ marginRight: theme.spacing(2) }}
          >
            cancel
          </Button>
        )}
        {onClose && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={onClose}
            disabled={savingInProgress || !open}
            style={{ marginRight: theme.spacing(2) }}
          >
            close
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={onSave}
          disabled={savingInProgress || !open || disableSaveButton}
          type={'submit'}
        >
          save changes
        </Button>
      </Box>
    </Drawer>
  );
};

export default SaveDrawer;
