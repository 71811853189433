import { LocationTypes } from 'requests/hooks/useFetchStandardFormLocations';
import { Customer, IDepartment, IOrganisation } from 'requests/nswag/atlas/AtlasClient';
import { sortArrayOfObjects } from 'utils/sortHelpers';

export interface StandardFormLocation {
  name: string;
  id: string;
  locationType: LocationTypes;
  parentId?: string;
}

export default class FormsHelper {
  getStandardFormLocations(customer: Customer | undefined) {
    if (!customer) return { locations: [], locationIds: [] };

    const standardFormLocations: StandardFormLocation[] = [];

    standardFormLocations.push({ id: customer.id, name: customer.name, locationType: LocationTypes.customer });

    const allOrganisations = customer?.organisations;

    const sortedOrganisations = sortArrayOfObjects(allOrganisations ?? [], 'name');
    sortedOrganisations.forEach((organisation: IOrganisation) => {
      standardFormLocations.push({
        id: organisation.id,
        name: organisation.name,
        locationType: LocationTypes.organisation,
      });

      const departments = sortArrayOfObjects(organisation.departments, 'name');
      departments.forEach((department: IDepartment) => {
        standardFormLocations.push({
          id: department.id,
          name: department.name,
          locationType: LocationTypes.department,
          parentId: organisation.id,
        });
      });
    });

    const locationIds = standardFormLocations.map((l) => l.id);

    return { locations: standardFormLocations, locationIds: locationIds };
  }
}
