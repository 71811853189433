import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormClient, CopyFormsRequest } from '../nswag/observations/ObservationsClient';

export default function useCopyForms() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: ICopyFormRequest) => {
      const client = new FormClient();
      return await client.copyForms(
        new CopyFormsRequest({
          sourceCustomerId: request.sourceCustomerId,
          destinationCustomerId: request.destinationCustomerId,
          destinationOwnerId: request.destinationOwnerId,
          formIds: request.formIds,
        }),
      );
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['STANDARD_FORMS', request.sourceCustomerId] });
    },
  });
}

interface ICopyFormRequest {
  sourceCustomerId: string;
  destinationCustomerId: string;
  destinationOwnerId: string;
  formIds: string[];
}
