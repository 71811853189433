import { useMutation, useQueryClient } from '@tanstack/react-query';
import CurrentUserInformation from '../../Authentication/CurrentUserInformation';
import { InternalTogglesClient, ToggleCustomerFeatureRequest } from '../nswag/toggles/TogglesClient';

async function updateCustomerToggle(request: ToggleCustomerFeatureRequest) {
  const client = new InternalTogglesClient();
  const customerId = CurrentUserInformation.Instance.getUserInformation().customerId;
  return await client.toggleCustomerFeature(request, request.customerId, request.toggleId, customerId);
}

export default function useUpdateCustomerToggle() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCustomerToggle,
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['TOGGLES', request.customerId.toLowerCase()] });
    },
  });
}
