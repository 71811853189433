import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useFetchCustomerStats } from 'requests/hooks/useFetchCustomerStats';
import { sortArrayOfObjects } from 'utils/sortHelpers';

type ColumnName = 'customerName' | 'residentsCount' | 'employeesCount' | 'contactsCount' | 'sum';

type TableSortSettings = {
  direction: 'asc' | 'desc';
  column: ColumnName;
};

type CustomerStatsTableRow = {
  customerName: string;
  residentsCount: number;
  employeesCount: number;
  contactsCount: number;
  sum: number;
};

const CustomerCountView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data: customerStats } = useFetchCustomerStats();
  const defaultSortSettings: TableSortSettings = { direction: 'asc', column: 'customerName' };
  const [sortSettings, setSortSettings] = useState(defaultSortSettings);

  const sortHandler = (column: ColumnName) => {
    const isAsc = sortSettings.column === column && sortSettings.direction === 'asc';

    setSortSettings({
      direction: isAsc ? 'desc' : 'asc',
      column: column as ColumnName,
    });
  };

  const customerStatsRows = useMemo(() => {
    const rows: CustomerStatsTableRow[] = [];
    customerStats?.forEach((stat) => {
      const statRow: CustomerStatsTableRow = {
        customerName: stat.customerName,
        residentsCount: stat.residentsCount,
        employeesCount: stat.employeesCount,
        contactsCount: stat.contactsCount,
        sum: stat.employeesCount + stat.residentsCount + stat.contactsCount,
      };
      rows.push(statRow);
    });
    return rows;
  }, [customerStats]);

  const calculateResidentsSum = () => {
    let total = 0;
    customerStatsRows.forEach((element) => {
      total += element.residentsCount;
    });
    return total;
  };
  const calculateEmployeesSum = () => {
    let total = 0;
    customerStatsRows.forEach((element) => {
      total += element.employeesCount;
    });
    return total;
  };
  const calculateContactsSum = () => {
    let total = 0;
    customerStatsRows.forEach((element) => {
      total += element.contactsCount;
    });
    return total;
  };

  return (
    <Box m={2}>
      <Box m={2}>
        <Typography variant="h5" component="span">
          User Counts
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell width={125}>
                  <TableSortLabel
                    active={sortSettings.column === 'customerName'}
                    direction={sortSettings.direction}
                    onClick={() => sortHandler('customerName')}
                  >
                    Customer
                  </TableSortLabel>
                </TableCell>
                <TableCell width={25}>
                  <TableSortLabel
                    active={sortSettings.column === 'residentsCount'}
                    direction={sortSettings.direction}
                    onClick={() => sortHandler('residentsCount')}
                  >
                    Residents
                  </TableSortLabel>
                </TableCell>
                <TableCell width={25}>
                  <TableSortLabel
                    active={sortSettings.column === 'employeesCount'}
                    direction={sortSettings.direction}
                    onClick={() => sortHandler('employeesCount')}
                  >
                    Employees
                  </TableSortLabel>
                </TableCell>
                <TableCell width={25}>
                  <TableSortLabel
                    active={sortSettings.column === 'contactsCount'}
                    direction={sortSettings.direction}
                    onClick={() => sortHandler('contactsCount')}
                  >
                    Relatives
                  </TableSortLabel>
                </TableCell>
                <TableCell width={25}>
                  <TableSortLabel
                    active={sortSettings.column === 'sum'}
                    direction={sortSettings.direction}
                    onClick={() => sortHandler('sum')}
                  >
                    Sum
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortArrayOfObjects(customerStatsRows, sortSettings.column, sortSettings.direction).map((row) => (
                <TableRow key={row.customerName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.customerName}
                  </TableCell>
                  <TableCell>{row.residentsCount}</TableCell>
                  <TableCell>{row.employeesCount}</TableCell>
                  <TableCell>{row.contactsCount}</TableCell>
                  <TableCell>{row.sum}</TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ borderTop: '2px solid black' }}>
                <TableCell component="th" scope="row">
                  Sum
                </TableCell>
                <TableCell>{calculateResidentsSum()}</TableCell>
                <TableCell>{calculateEmployeesSum()}</TableCell>
                <TableCell>{calculateContactsSum()}</TableCell>
                <TableCell>{calculateResidentsSum() + calculateEmployeesSum() + calculateContactsSum()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default CustomerCountView;
