import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LocationsInternalClient } from '../nswag/atlas/AtlasClient';

export default function useDeleteApartment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: IDeleteApartmentRequest) => {
      const client = new LocationsInternalClient();
      return await client.deleteApartment(request.apartmentId);
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['CUSTOMER', request.customerId] });
    },
  });
}

interface IDeleteApartmentRequest {
  apartmentId: string;
  customerId: string;
}
