import { Typography, Grid } from '@mui/material';
import React from 'react';
import { Apartment } from 'requests/nswag/atlas/AtlasClient';
import ApartmentDotMenu from 'Components/ApartmentDotMenu';
import { TreeItem } from '@mui/x-tree-view';

type LocationApartmentProps = {
  apartment: Apartment;
};

const LocationApartment: React.FC<LocationApartmentProps> = (props) => {
  const { apartment } = props;

  return (
    <TreeItem
      key={apartment.id}
      itemId={apartment.id}
      label={
        <Grid container direction="row" justifyContent="space-between">
          <Typography p={1}>{apartment.name}</Typography>
          <ApartmentDotMenu apartment={apartment} />
        </Grid>
      }
    />
  );
};

export default LocationApartment;
