import { Authentication } from 'Authentication/Authentication';
import { getSessionId } from 'requests/sessionId';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const client = axios.create({
  headers: {
    'Content-Type': 'application/json;',
    Accept: 'application/json',
    'sekoia.session_id': getSessionId(),
  },
});

export async function GetPlatformsList(compassUrl: string) {
  const response = await client.get(compassUrl + '/api/backend/platforms/list', {
    headers: { Authorization: `Bearer ${await Authentication.Instance.GetToken()}`, 'sekoia.correlation_id': uuidv4() },
  });
  return response.data;
}
