import { Typography, Grid } from '@mui/material';
import React from 'react';
import { Department } from 'requests/nswag/atlas/AtlasClient';
import DepartmentDotMenu from 'Components/DepartmentDotMenu';
import { TreeItem } from '@mui/x-tree-view';
import LocationApartment from './LocationApartment';

type LocationsDepartmentProps = {
  department: Department;
};

const LocationsDepartment: React.FC<LocationsDepartmentProps> = (props) => {
  const { department } = props;
  return (
    <TreeItem
      key={department.id}
      itemId={department.id}
      label={
        <Grid container direction="row" justifyContent="space-between">
          <Typography p={1}>{department.name}</Typography>
          <DepartmentDotMenu department={department} />
        </Grid>
      }
    >
      {department.apartments.map((apartment) => {
        return <LocationApartment apartment={apartment} key={apartment.id} />;
      })}
    </TreeItem>
  );
};

export default LocationsDepartment;
