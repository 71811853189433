import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, useTheme } from '@mui/material';

type LoadingScreenProps = {
  width?: number;
  style?: React.CSSProperties;
};

function LoadingScreen({ width = 400, style }: LoadingScreenProps) {
  const theme = useTheme();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3),
      }}
    >
      <div style={style}>
        <Box width={width}>
          <LinearProgress />
        </Box>
      </div>
    </Box>
  );
}

export default LoadingScreen;
