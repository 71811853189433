import React from 'react';
import { Box, List, MenuItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

function NavBar() {
  const content = (
    <List>
      <MenuItem component={RouterLink} to={'/locations'}>
        Locations
      </MenuItem>
      <MenuItem component={RouterLink} to={'/toggles'}>
        Toggles
      </MenuItem>
      <MenuItem component={RouterLink} to={'/platforms'}>
        Platforms
      </MenuItem>
      <MenuItem component={RouterLink} to={'/standardforms'}>
        Standard forms
      </MenuItem>
      <MenuItem component={RouterLink} to={'/kombit'}>
        Kombit
      </MenuItem>
      <MenuItem component={RouterLink} to={'/standardtasks'}>
        Standard Tasks
      </MenuItem>
      <MenuItem component={RouterLink} to={'/customercount'}>
        User Counts
      </MenuItem>
    </List>
  );

  return <Box className="no-print">{content}</Box>;
}

export default NavBar;
