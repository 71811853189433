import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LocationsInternalClient, CreateLocationRequest } from '../nswag/atlas/AtlasClient';

export default function useCreateDepartment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: ICreateDepartmentRequest) => {
      const client = new LocationsInternalClient();
      return await client.createDepartment(request.createLocationRequest);
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['CUSTOMER', request.customerId] });
    },
  });
}

interface ICreateDepartmentRequest {
  createLocationRequest: CreateLocationRequest;
  customerId: string;
}
