import { Box, Typography, Grid, Button } from '@mui/material';
import React from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import LocationOrganisation from './LocationOrganisation';
import { useLocationContext } from './LocationProvider';

const Locations: React.FC = () => {
  const { customer, setCreateLocation } = useLocationContext();

  return (
    <Box m={6}>
      <Grid container direction="row" justifyContent="space-between">
        <Typography style={{ fontSize: 32 }}>{customer.name}</Typography>
        <Button onClick={() => setCreateLocation(customer)}>Add organisation</Button>
      </Grid>
      <Box mb={2}>
        <SimpleTreeView sx={{ flexGrid: 1 }} disableSelection>
          {customer.organisations.map((organisation) => {
            return <LocationOrganisation organisation={organisation} key={organisation.id} />;
          })}
        </SimpleTreeView>
      </Box>
    </Box>
  );
};
export default Locations;
