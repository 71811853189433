import React from 'react';
import { LocationProvider } from './LocationProvider';
import Locations from './Locations';

const LocationsPage: React.FC = () => {
  return (
    <LocationProvider>
      <Locations />
    </LocationProvider>
  );
};

export default LocationsPage;
