import React from 'react';
import { useStandardFormContext } from './StandardFormProvider';
import StandardFormLocationRow from './StandardFormLocationRow';

function StandardFormLocations() {
  const { selectedLocations } = useStandardFormContext();
  return (
    <>
      {selectedLocations.map((location) => (
        <StandardFormLocationRow key={location.id} location={location} />
      ))}
    </>
  );
}

export default StandardFormLocations;
