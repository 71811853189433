import React, { useMemo, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Organisation } from '../requests/nswag/atlas/AtlasClient';
import { ExtendedCustomerResponse } from './Homepage';
import { sortArrayOfObjects } from '../utils/sortHelpers';
import { useFetchCustomer } from 'requests/hooks/useFetchCustomer';

type CustomerSelectProps = {
  customer: ExtendedCustomerResponse | null;
  title: string;
  setSelectedOrganisationId: (id: string) => void;
};

const OrganisationSelector: React.FC<CustomerSelectProps> = (props) => {
  const { customer, title, setSelectedOrganisationId } = props;
  const { data: customerData } = useFetchCustomer(customer?.id);
  const [selectedOrganisation, setSelectedsOrganisation] = useState('');

  const organisations = useMemo(() => {
    if (!customerData) return [];
    return sortArrayOfObjects(customerData.organisations, 'name');
  }, [customerData]);

  const handleChange = (event: SelectChangeEvent) => {
    const id = event.target.value as string;
    setSelectedsOrganisation(id);
    setSelectedOrganisationId(id);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="customer-select-label">{title}</InputLabel>
      <Select labelId="Orginasation-select-label" value={selectedOrganisation} label={title} onChange={handleChange}>
        {organisations.map((organisation: Organisation) => (
          <MenuItem value={organisation.id} key={organisation.id}>
            {organisation.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrganisationSelector;
