import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React from 'react';
import { CustomerToggleResponse } from 'requests/nswag/toggles/TogglesClient';
import { useTogglesContext } from './TogglesProvider';
import TogglesOrganisation from './TogglesOrganisation';

type TogglesOrganisationsProps = {
  customerToggle: CustomerToggleResponse;
};

const TogglesOrganisations: React.FC<TogglesOrganisationsProps> = (props) => {
  const { customerToggle } = props;
  const { organisations } = useTogglesContext();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Organisation name</TableCell>
            <TableCell align="right">Enabled/disabled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organisations.map((organisation) => (
            <TogglesOrganisation key={organisation.id} organisation={organisation} customerToggle={customerToggle} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TogglesOrganisations);
