import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Apartment, Department, Organisation } from 'requests/nswag/atlas/AtlasClient';
import useDeleteApartment from 'requests/hooks/useDeleteApartment';
import useDeleteDepartment from 'requests/hooks/useDeleteDepartment';
import useDeleteOrganisation from 'requests/hooks/useDeleteOrganisation';

interface IDeleteLoctionDialogProps {
  location: Organisation | Department | Apartment;
  closeDialog: () => void;
  customerId: string;
}

export default function DeleteLocationDialog(props: IDeleteLoctionDialogProps) {
  const { location, closeDialog, customerId } = props;
  const { mutateAsync: deleteOrganisationMutation, isPending: deleteOrganisationIsPending } = useDeleteOrganisation();
  const { mutateAsync: deleteDepartmentMutation, isPending: deleteDepartementIsPending } = useDeleteDepartment();
  const { mutateAsync: deleteApartmentMutation, isPending: deleteApartmentIsPending } = useDeleteApartment();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteLocation = async () => {
    try {
      if (location instanceof Organisation) {
        await deleteOrganisationMutation({
          organisationId: location.id,
          customerId: customerId,
        });
      }
      if (location instanceof Department) {
        await deleteDepartmentMutation({
          departmentId: location.id,
          customerId: customerId,
        });
      }
      if (location instanceof Apartment) {
        await deleteApartmentMutation({
          apartmentId: location.id,
          customerId: customerId,
        });
      }
    } catch (error) {
      closeDialog();
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
      });
    }
    closeDialog();
  };

  return (
    <Dialog open>
      <DialogTitle>Delete location</DialogTitle>
      <DialogContent>
        {deleteOrganisationIsPending || deleteDepartementIsPending || deleteApartmentIsPending ? (
          <LinearProgress />
        ) : (
          <Typography>Are you sure you want to delete this location?</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            closeDialog();
            event.stopPropagation();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={async (event) => {
            event.stopPropagation();
            await handleDeleteLocation();
          }}
          style={{ color: '#d32f2f' }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
