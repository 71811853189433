import { StandardFormLocation } from 'Components/StandardForms/FormsHelper';
import LocationsSelectorRender from './LocationsSelectorRender';
import React, { ReactNode } from 'react';
import { SelectChangeEvent } from '@mui/material';

type LocationsSelectorProps = {
  selectedLocationsIds: string[];
  onSelectedLocationsChange: (locationIds: string[]) => void;
  locations: StandardFormLocation[];
  singleSelector?: boolean;
};

function LocationsSelector({
  selectedLocationsIds,
  onSelectedLocationsChange,
  locations,
  singleSelector = false,
}: LocationsSelectorProps) {
  const handleSelect = (event: SelectChangeEvent<string[]>, child: ReactNode) => {
    const key = (child as React.ReactElement).key;
    if (key !== '.$ignore') {
      const value = singleSelector ? ([event.target.value] as string[]) : (event.target.value as string[]);
      onSelectedLocationsChange(value);
    }
  };

  const handleAllLocationsSelectedOrDeselected = () => {
    const allIds = locations.map((x) => x.id);

    if (selectedLocationsIds.length < allIds.length) onSelectedLocationsChange(allIds);
    else onSelectedLocationsChange([]);
  };

  return (
    <LocationsSelectorRender
      locations={locations}
      selectedLocationsIds={selectedLocationsIds}
      handleAllLocationsSelectedOrDeselected={handleAllLocationsSelectedOrDeselected}
      handleSelect={handleSelect}
      singleSelector={singleSelector}
    />
  );
}

export default LocationsSelector;
