import { useMutation, useQueryClient } from '@tanstack/react-query';
import { InternalClient, CopyFoldersAndContentRequest } from 'requests/nswag/observations/ObservationsClient';

export default function useCopyFolders() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: ICopyFolderAndContentRequest) => {
      const client = new InternalClient();
      return await client.copyFolder(
        new CopyFoldersAndContentRequest({
          sourceCustomerId: request.sourceCustomerId,
          destinationCustomerId: request.destinationCustomerId,
          destinationOwnerId: request.destinationOwnerId,
          folderIds: request.folderIds,
        }),
      );
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['FORM_FOLDERS'] });
      queryClient.invalidateQueries({ queryKey: ['STANDARD_FORMS', request.sourceCustomerId] });
    },
  });
}

interface ICopyFolderAndContentRequest {
  sourceCustomerId: string;
  destinationCustomerId: string;
  destinationOwnerId: string;
  folderIds: string[];
}
