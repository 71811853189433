import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CustomerResponse, LocationsInternalClient } from '../nswag/atlas/AtlasClient';

async function getAllCustomers() {
  const client = new LocationsInternalClient();
  return client.getCustomers();
}

export function useFetchCustomers(): UseQueryResult<CustomerResponse[]> {
  return useQuery({ queryKey: ['CUSTOMERS'], queryFn: getAllCustomers });
}
