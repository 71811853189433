import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LocationsInternalClient, RenameLocationRequest } from '../nswag/atlas/AtlasClient';

export default function useRenameLocation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: IRenameLocationRequest) => {
      const client = new LocationsInternalClient();
      return await client.renameLocation(request.id, new RenameLocationRequest({ name: request.name }));
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: ['CUSTOMER', request.customerId] });
    },
  });
}

interface IRenameLocationRequest {
  id: string;
  name: string;
  customerId: string;
}
