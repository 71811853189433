import { TableRow, TableCell, Switch, Button } from '@mui/material';
import React, { useMemo, useState } from 'react';
import useUpdateCustomerToggle from 'requests/hooks/useUpdateCustomerToggle';
import { Organisation } from 'requests/nswag/atlas/AtlasClient';
import {
  CustomerToggleResponse,
  FeatureEnabledForOrganisation,
  ToggleCustomerFeatureRequest,
  ToggleOrganisationFeatureRequest,
} from 'requests/nswag/toggles/TogglesClient';
import { useTogglesContext } from './TogglesProvider';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';

type TogglesOrganisationProps = {
  organisation: Organisation;
  customerToggle: CustomerToggleResponse;
};

const TogglesOrganisation: React.FC<TogglesOrganisationProps> = (props) => {
  const { organisation, customerToggle } = props;
  const { enqueueSnackbar } = useSnackbar();
  const customerToggleMutation = useUpdateCustomerToggle();
  const { customerLocation } = useTogglesContext();
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>();
  const [processing, setIsProcessing] = useState(false);

  const orgToggle = useMemo(() => {
    const t = customerToggle.featureEnabledForOrganisations.find(
      (toggle) => toggle.organisationId.toLowerCase() === organisation.id.toLowerCase(),
    );
    if (t) setIsEnabled(t.isEnabled);
    return t;
  }, [customerToggle.featureEnabledForOrganisations, organisation.id]);

  const updateCustomerToggle = async (state: boolean | undefined) => {
    setIsProcessing(true);
    setIsEnabled(state);
    const organisationToggles = customerToggle.featureEnabledForOrganisations.map(
      (orgToggle: FeatureEnabledForOrganisation) =>
        new ToggleOrganisationFeatureRequest({
          customerId: customerLocation.id,
          organisationId: orgToggle.organisationId.toLowerCase(),
          isEnabled: orgToggle.isEnabled,
        }),
    );

    const nrOfTogglesInorganisationToggles = organisationToggles.findIndex(
      (ft: ToggleOrganisationFeatureRequest) => ft.organisationId === organisation.id.toLowerCase(),
    );

    if (nrOfTogglesInorganisationToggles > -1) {
      if (state === undefined) {
        organisationToggles.splice(nrOfTogglesInorganisationToggles, 1);
      } else organisationToggles[nrOfTogglesInorganisationToggles].isEnabled = state;
    } else {
      organisationToggles.push(
        new ToggleOrganisationFeatureRequest({
          customerId: customerLocation.id,
          organisationId: organisation.id.toLowerCase(),
          isEnabled: true,
        }),
      );
    }

    const request = new ToggleCustomerFeatureRequest({
      customerId: customerLocation.id,
      toggleId: customerToggle.toggleId,
      isEnabled: customerToggle.isEnabledForCustomer,
      organisationToggles: organisationToggles,
    });

    try {
      await customerToggleMutation.mutateAsync(request);
    } catch (error) {
      enqueueSnackbar('Something went wrong trying to update toggles', {
        variant: 'error',
      });
      setIsEnabled(!state);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {organisation.name}
      </TableCell>

      <TableCell component="th" scope="row" align="right">
        {!orgToggle ? (
          <Button
            onClick={() => {
              updateCustomerToggle(true);
            }}
          >
            Activate organisation toggle
          </Button>
        ) : (
          <Button
            onClick={() => {
              updateCustomerToggle(undefined);
            }}
          >
            Reset to use client level toggle
          </Button>
        )}
        {isEnabled !== undefined ? (
          <>
            {processing ? (
              <CircularProgress size={'23px'} sx={{ ml: '19px', mr: 2 }} />
            ) : (
              <Switch
                checked={isEnabled}
                color={isEnabled ? 'primary' : 'secondary'}
                onClick={() => updateCustomerToggle(!isEnabled)}
              />
            )}
          </>
        ) : (
          <Switch checked={customerToggle.isEnabledForCustomer} disabled />
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TogglesOrganisation);
