import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './Authentication/AuthProvider';
import Homepage from './Components/Homepage';
import CustomerNotSelectedErrorView from './Components/CustomerNotSelectedErrorView';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './Components/SnackbarCloseButton';
import { Router } from 'Routing/Router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: 1000,
      refetchOnWindowFocus: false,
    },
  },
});

export const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            action={(snackbarKey: SnackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
            <Homepage>
              <CustomerNotSelectedErrorView>
                <Router />
              </CustomerNotSelectedErrorView>
            </Homepage>
          </SnackbarProvider>
        </QueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
