import { Grid, Typography } from '@mui/material';
import React from 'react';
import CurrentUserInformation from '../Authentication/CurrentUserInformation';
import PropTypes from 'prop-types';

const NotSekoiaAdminErrorView: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const isSekoiaAdmin = CurrentUserInformation.Instance.getUserInformation().roles.includes('Admin');
  if (isSekoiaAdmin) return <div style={{ width: 'inherit' }}>{props.children}</div>;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: 'calc(100vh - 64px)' }}
    >
      <Typography align="center" variant={'h2'} color="textPrimary">
        Error
      </Typography>
      <Typography align="center" variant="subtitle1" color="textSecondary">
        You do not have access to this tool
      </Typography>
    </Grid>
  );
};

NotSekoiaAdminErrorView.propTypes = {
  children: PropTypes.any,
};

export default NotSekoiaAdminErrorView;
